<template>
  <v-card class="mb-4">
    <v-toolbar dense flat>
      <span>Rule {{ index + 1 }}</span>
      <v-spacer></v-spacer>
      <v-btn outlined small @click="$emit('remove')">
        Remove Condition
      </v-btn>
    </v-toolbar>
    <v-card-text class="pb-0">
      <v-row>
        <v-col class="mb-0">
          <v-select :value="field" :items="inputs" item-value="label" item-text="label" label="Condition Field" return-object outlined @change="fieldChanged"></v-select>
        </v-col>
        <v-col class="mb-0">
          <v-select v-if="fieldType === 'select' || fieldType === 'combobox' || fieldType === 'autocomplete' || fieldType === 'radio'" v-model="value" :items="items" :multiple="fieldType !== 'radio'" label="Selected Value(s)" outlined></v-select>
          <v-select v-else-if="fieldType === 'checkbox' || fieldType === 'switch'" v-model="value" :items="items" label="Selected Value" outlined></v-select>
          <v-text-field v-else-if="fieldType === 'number'" v-model="minValue" label="Min Value" type="number" hint="Leave blank for no minimum" persistent-hint outlined></v-text-field>
        </v-col>
        <v-col v-if="fieldType === 'number'" class="mb-0">
          <v-text-field v-model="maxValue" label="Max Value" type="number" hint="Leave blank for no maximum" persistent-hint outlined></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    condition: {
      type: Object,
      required: true
    },
    inputs: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  setup (props, { emit }) {
    const field = ref(props.condition.field || '')
    const fieldType = ref('')
    const items = ref([])
    const value = ref(props.condition.value || null)
    const minValue = ref('')
    const maxValue = ref('')

    function fieldChanged ({ label, input, options }) {
      field.value = label
      fieldType.value = input
      if (Array.isArray(options)) items.value = options
      else if (input === 'checkbox') {
        items.value = [{ text: 'Checked', value: true }, { text: 'Unchecked', value: false }]
      } else if (input === 'switch') {
        items.value = [{ text: 'Switch On', value: true }, { text: 'Switch Off', value: false }]
      } else if (input === 'number') {
        if (props.condition.field === label && Array.isArray(props.condition.value) && props.condition.value.length === 2) {
          value.value = props.condition.value
          minValue.value = props.condition.value[0]
          maxValue.value = props.condition.value[1]
        } else {
          value.value = ['', '']
        }
      }

      emit('update', { field: label })
    }
    watch(value, (val) => {
      // console.log('Updating value: ' + val)
      emit('update', { value: val })
    })
    watch(minValue, (val) => {
      if (fieldType.value === 'number' && Array.isArray(value.value) && value.value[0] !== val) {
        value.value.splice(0, 1, val)
      }
    })
    watch(maxValue, (val) => {
      if (fieldType.value === 'number' && Array.isArray(value.value) && value.value[1] !== val) {
        value.value.splice(1, 1, val)
      }
    })

    function validate () {
      if (field.value == null || field.value === '') return false
      else if (fieldType.value === 'number') {
        return (minValue.value !== '' || maxValue.value !== '')
      } else if (fieldType.value === 'repeatable-block') {
        // Not yet implemented
        return false
      } else if (fieldType.value === 'checkbox' || fieldType.value === 'switch') {
        return value.value === true || value.value === false
      } else if (fieldType.value === 'select' || fieldType.value === 'combobox' || fieldType.value === 'autocomplete') {
        // Make sure the value is an array with length > 0
        return Array.isArray(value.value) && value.value.length > 0
      }
      return false
    }

    return {
      field,
      fieldType,
      items,
      value,
      minValue,
      maxValue,
      fieldChanged,
      validate
    }
  }
}
</script>
